<template>
    <div class="Meeting">
        <div class="warp">
            <div class="nav-list" v-show="leftShow">
                <div class="logo">
                    <img :src="userinfo.Avatar" alt=""/>
                    <div
                            class="header-title"
                            @mouseover="TitleShow"
                            @mouseLeave="TitleHide"
                    >
                        <p class="header-name">
                            {{ Mybase ? Mybase.ParticipantName : userinfo.name }}-{{
                            Mybase ? Mybase.ParticipantPosition : ''
                            }}
                        </p>
                        <p style="height:36px;overflow: hidden" v-if="!MyinfoStatus">
                            {{ Mybase.Info }}
                        </p>
                        <a-textarea
                                v-show="MyinfoStatus"
                                v-model="MyDetails"
                                placeholder="请输入个人简介"
                                :autosize="{ minRows: 6 }"
                        />
                        <div v-if="TitleStatus">
                            <a-button
                                    v-if="!MyinfoStatus"
                                    style="margin-top: 15px"
                                    @click="EditInfo"
                            >编辑
                            </a-button
                            >
                            <a-button v-else style="margin-top: 15px" @click="submitMyinfo"
                            >确认
                            </a-button
                            >
                        </div>
                    </div>
                </div>
                <!-- <h1 class="nav-study">学习任务(共{{Mycount}}个学习任务)</h1>
                        <ul class="navs-list">
                            <li @click="loadData(1)" class="my-course my-base">
                                <span class="nav-num" v-if="Mycount">{{Mycount}}</span>
                                <div class="nav-weight">我的课程</div>
                            </li>
                            <li @click="MyQuire('PaperId')" class="my-Examination my-base">
                                <span class="nav-num" v-if="paperCount">{{paperCount}}</span>
                                <div class="nav-weight my-base">我的考试</div>
                            </li>
                            <li @click="MyQuire('QuestionnaireId')" class="my-ques my-base">
                                <span class="nav-num" v-if="quireCount">{{quireCount}}</span>
                                <div class="nav-weight ">我的问卷</div>
                            </li>
                            <li class="my-note my-base" @click="Mynote">
                                <span class="nav-num" v-if="noteCount">{{noteCount}}</span>
                                <div class="nav-weight">我的笔记</div>
                            </li>
                        </ul> -->
            </div>
            <div
                    class="cut-btn"
                    @click="_cutShow"
                    @mouseover="_hintShow"
                    @mouseout="_hintShow"
            >
                <a-icon
                        :type="leftShow ? 'caret-left' : 'caret-right'"
                        style="color:#8A8A8A"
                />
                <div class="hint-msg" v-show="hintMsg">
                    <div class="san"></div>
                    <span>{{ leftShow ? '收起侧边面板' : '展开侧边面板' }}</span>
                </div>
            </div>
            <div class="list-warp" style="margin-bottom: 0px;">
                <div class="search-warp">
                    <div
                            class="flex"
                            style="border-bottom: 1px solid #EDEDEDFF;    padding: 0px 24px;"
                    >
                        <h1 class="search-title">{{ TitleText }}</h1>
                        <a-button
                                class="search-item-button"
                                @click="createCourse"
                                type="primary"
                        >创建课程
                        </a-button
                        >
                    </div>

                    <div class="search-item">
                        <div class="search-flex" v-if="!CourseStatus">
                            <div class="search-list-item">
                                <span class="search-item-label ">学习主题</span>
                                <a-input
                                        v-model="Keyword"
                                        placeholder="请输入关键字"
                                        class="search-item-input"
                                />
                            </div>
                        </div>
                        <div v-if="!CourseStatus" class="search-flex">
                            <div class="search-list-item">
                                <span class="search-item-label ">主讲人</span>
                                <a-select
                                        style="flex:1;margin-right:15px"
                                        v-model="Speaker"
                                        placeholder="请选择"
                                >
                                    <a-select-option v-for="item in AllSpeaker" :value="item">{{
                                        item
                                        }}
                                    </a-select-option>
                                </a-select>

                                <span class="search-item-label ">课程类型</span>
                                <a-select
                                        style="flex:1"
                                        placeholder="请选择"
                                        v-model="Category">
                                    <a-select-option v-for="item in categoryList" :value="item.Id">{{ item.title }}</a-select-option>
                                </a-select>
                            </div>
                        </div>



<!--                        @change="onChange"-->
                        <div class="search-flex" style="width: 300px;">
                            <span class="search-item-label "> 日期</span>
                            <a-range-picker
                                    @change="_onChange"
                                    :default-value="[StartTime, EndTime]"

                                    style="flex:1;margin-left:15px;width: 236px;"  :format="dateFormat" />
                        </div>


                        <div class="search-flex" style="width:200px" v-if="!CourseStatus">
                            <div class="search-list-item">
                                <a-button @click="emptyData" class="search-item-button"
                                >重置
                                </a-button
                                >
                                <a-button
                                        size="large"
                                        class="search-item-button"
                                        v-if="!CourseStatus"
                                        @click="loadData"
                                        type="primary"
                                >查询
                                </a-button
                                >
                                <a-button
                                        size="large"
                                        class="search-item-button"
                                        v-else
                                        @click="MyQuire(QuireType)"
                                        type="primary"
                                >查询
                                </a-button
                                >
                            </div>
                        </div>
                    </div>
                </div>


                <div class="content-title flex"  >
                    <span style="color: #999">
                        <i
                                class="iconfont icon-tubiao_liebiao"
                                :class="showType == 1 ? 'ant-menu-item-selected' : ''"
                                @click="_showType(1)"
                        ></i>
                        <i
                                class="iconfont icon-tubiao_kapian"
                                :class="showType == 2 ? 'ant-menu-item-selected' : ''"
                                @click="_showType(2)"
                        ></i>
                    </span>
                </div>


                <!--学习计划-->
                <a-row  v-show="showType==2"  style="  background: #ffffff;padding: 22px 0px 22px 22px;">
                    <a-col
                            v-if="!CourseStatus"
                            :xl="12"
                            :xxl="8"
                            :md="12"
                            v-for="(item, index) in infoData"
                            @click="SaveLink(item.Id)"
                    >
                        <div class="list">
                            <div class="list-img">
                                <img :src="item.Photo + '?imageView2/1/w/550/h/285'" alt=""/>
                                <div class="img-shadw" v-if="item.Speaker.length > 0">
                                    <a-avatar
                                            style="float: left"
                                            :size="45"
                                            icon="user"
                                            shape="circle"
                                            :src="item.Speaker[0].Avatar + '?imgview/1/w/324/h/170'"
                                    />
                                    <span class="img-text" style="margin-left: 14px">{{
                    item.Speaker[0].NAME
                  }}</span>
                                    <span class="img-text" style="float: right;"
                                    >{{ item.JoinCount }}人参与</span
                                    >
                                    <div style="clear: both"></div>
                                </div>
                            </div>

                            <div class="list-detail">
                                <div style="display: flex">
                                    <div class="list-title">{{ item.Title }}</div>
                                    <span
                                            class="list-status"
                                            :class="item.State == '进行中' ? 'list-status-red' : ''"
                                    >{{ item.State }}</span
                                    >
                                </div>
                                <div class="list-addr">
                                    <i class="iconfont icon-weizhi"></i>
                                    {{ item.MeetingAddress }}
                                </div>
                                <div
                                        class="list-addr"
                                        style="font-size:14px;padding-right:44px;position:relative;"
                                >
                                    <i class="iconfont icon-shijian"></i>
                                    {{ item.StartTime | sliceFn(0, 16) }}-{{
                                    item.EndTime | sliceFn(0, 16)
                                    }}
                                    <!-- .slice(0,16) -->
                                    <span style="position:absolute;top:2px;right:0px;">
                    <i
                            v-if="item.power == 1"
                            @click.capture.stop="SaveLink(item.Id, item.power)"
                            @mouseover="_hoverActive(index, 1)"
                            :class="active2 == index ? 'ant-menu-item-selected' : ''"
                            class="iconfont icon-bianji"
                    ></i>
                    <a-popconfirm
                            v-if="item.power == 1"
                            placement="bottom"
                            cancelText="取消"
                            okText="确定"
                            @confirm.capture.stop="MeetingDel(item.Id)"
                            @cancel="maxClassify = -1"
                    >
                      <template slot="title">
                        <p>确定删除么？</p>
                      </template>
                      <i
                              @mouseover="_hoverActive(index, 2)"
                              class="iconfont icon-shanchu-"
                              :class="active == index ? 'ant-menu-item-selected' : ''"
                      ></i>
                    </a-popconfirm>
                  </span>
                                </div>
                            </div>
                        </div>
                    </a-col>
                    <!--我的笔记-->
                    <a-col
                            v-if="NoteStatus"
                            :xl="12"
                            :xxl="8"
                            :md="12"
                            v-for="(item, index) in NotaList"
                    >
                        <div class="list">
                            <div class="note-list">
                                <div class="flex" style="width: 95%">
                                    <div class="flex">
                                        <a-avatar
                                                v-if="item.Avatar"
                                                :size="42"
                                                icon="user"
                                                shape="circle"
                                                :src="item.Avatar"
                                        />
                                        <div style="margin-left: 15px">
                                            <div>{{ item.CreateUserName }}</div>
                                            <div>{{ item.CreateTime }}</div>
                                        </div>
                                    </div>
                                    <a-dropdown>
                                        <a class="ant-dropdown-link" href="#">
                                            <a-icon type="down"/>
                                        </a>
                                        <a-menu slot="overlay">
                                            <a-menu-item>
                                                <a-popover
                                                        title="编辑笔记信息"
                                                        trigger="click"
                                                        v-model="visible"
                                                >
                                                    <template slot="content">
                            <textarea
                                    style="width: 260px;height: 100px"
                                    :id="item.Id"
                                    :rows="4"
                                    :value="item.Content"
                            ></textarea>
                                                        <div style="margin-top: 20px;display: flex">
                                                            <a-button style="margin-right: 20px" @click="hide"
                                                            >取消
                                                            </a-button
                                                            >
                                                            <a-button
                                                                    type="primary"
                                                                    @click="SaveNote(item.Id)"
                                                            >编辑
                                                            </a-button
                                                            >
                                                        </div>
                                                    </template>

                                                    <a href="javascript:;">编辑</a>
                                                    <!---->
                                                </a-popover>
                                            </a-menu-item>
                                            <a-menu-item>
                                                <a href="javascript:;">
                                                    <a-popconfirm
                                                            placement="bottom"
                                                            cancelText="取消"
                                                            okText="确定"
                                                            @confirm="commentdel(item.Id)"
                                                            @cancel="maxClassify = -1"
                                                    >
                                                        <template slot="title">
                                                            <p>确定删除么？</p>
                                                        </template>
                                                        删除
                                                    </a-popconfirm>
                                                </a>
                                            </a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                </div>
                                <div class="meeting-title flex">
                                    <div class="title-text">{{ item.Title }}</div>
                                    <img
                                            style="flex: 1;height: 60px"
                                            :src="item.Photo + '?imageView2/1/w/300/h/155'"
                                            alt=""
                                    />
                                </div>
                                <div class="notedetail">
                                    {{ item.Content }}
                                </div>
                            </div>
                        </div>
                    </a-col>
                    <!--我的问卷、试卷-->
                    <a-col
                            :xl="12"
                            :xxl="8"
                            :md="12"
                            v-if="QuireStatus"
                            v-for="item in CourseList"
                            @click="routeFn(item)"
                    >
                        <div class="list">
              <span class="course-status">{{
                item.UserStatus == '0' ? '未完成' : '已完成'
              }}</span>
                            <span class="course-title">{{ item.Title }}</span>
                            <span class="courser-time">时间:&nbsp;{{ item.plan_time }} </span>
                            <span class="courser-time">来源:&nbsp;{{ item.plan_title }}</span>
                        </div>
                    </a-col>
                </a-row>



                <div style="margin-left: 20px; margin-top: 10px;" v-show="showType==1">

                    <div class="list-item" v-for="(item,index) in infoData"  >
                        <!--标题信息-->
                        <div class="order-base-msg"  @click="SaveLink(item.Id)" >
                            <div class="order-title">
                                <a-tag style="padding:1px 9px" :color="_statusColor(item.State)">{{item.State}}</a-tag>
                                {{item.Title}}
                            </div>
                            <div class="created-msg">
                                <div class="created-person-msg">
                                    <img :src="item.Speaker[0].Avatar" alt="">
                                    <span> {{item.Speaker[0].Name}} </span>
                                </div>
                                <div class="created-date">{{item.StartTime}}-{{item.EndTime}}</div>
                                <div class="created-date"> {{item.CategoryText}}  </div>
                            </div>
                        </div>



                        <div>

                            <span style="display: inline-block;margin-right: 100px;" > {{item.JoinCount}}人参与 </span>

                            <span style="">
                                <i
                                        v-if="item.power == 1"
                                        @click.capture.stop="SaveLink(item.Id, item.power)"
                                        @mouseover="_hoverActive(index, 1)"
                                        :class="active2 == index ? 'ant-menu-item-selected' : ''"
                                        class="iconfont icon-bianji"
                                ></i>
                                <a-popconfirm
                                        v-if="item.power == 1"
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm.capture.stop="MeetingDel(item.Id)"
                                        @cancel="maxClassify = -1"
                                >
                                  <template slot="title">
                                    <p>确定删除么？</p>
                                  </template>
                                  <i
                                          @mouseover="_hoverActive(index, 2)"
                                          class="iconfont icon-shanchu-"
                                          :class="active == index ? 'ant-menu-item-selected' : ''"
                                  ></i>
                                </a-popconfirm>
                              </span>
                        </div>

                    </div>
                </div>




                <div style="margin-right: 50px">
                    <a-pagination
                            v-if="pageTotal > Limit"
                            style="margin-top: 35px;text-align: right;"
                            @change="ChangePage"
                            :pageSize="Limit"
                            :total="pageTotal"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import moment from 'moment';

    export default {
        name: 'meeting',
        data() {
            return {
                tableTable:[
                    {id:'11',name:'wxj'},
                    {id:'222',name:'xjp'},
                ],
                showType:1,
                infoData: [],
                StartTime:null,
                EndTime:null,
                AllSpeaker: '',
                QuireData: '',
                Keyword: '',
                Speaker: '',
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                active: -1,
                active2: -1,
                Mycount: 0,
                paperCount: 0,
                quireCount: 0,
                noteCount: 0,
                CourseList: '',
                CourseStatus: false,
                leftShow: false,
                hintMsg: false,
                MyinfoStatus: false,
                MyDetails: '',
                Mybase: '',
                MybaseId: '',
                QuireType: '',
                UserStatus: '',
                NoteStatus: false,
                NotaList: '',
                TitleStatus: false,
                TitleText: '学习计划',
                visible: false,
                QuireStatus: false,
                pageTotal: '',
                Limit: 12,
                Page: 1,
                categoryObject:{},
                categoryList:[],
                Category:'',
                dateFormat:"YYYY-MM-DD",
            }
        },
        // created(){
        //     this.loadData();
        //     this.MybaseInfo();
        // },
        mounted() {
            this.loadData()
            this.MybaseInfo()
            this._categoryList();
        },

        created(){

            // this.StartTime = moment(new Date()).add(-1, 'month').format(this.dateFormat);
            // this.EndTime = moment(new Date()).format(this.dateFormat);
        },

        methods: {
            //跳转问卷试卷fn
            _categoryList() {
                let self = this
                this.$axios.get(
                    1200,
                    {
                        label: 'Study'
                    },
                    res => {
                        
                        for(let i=0;i<res.data.data.length;i++){
                            self.categoryObject[res.data.data[i].Id] =  res.data.data[i].title;
                        }
                        self.categoryList = res.data.data ;

                    }
                )
            },
            routeFn(obj) {
                console.log(obj)
                let type = 2
                if (this.TitleText == '我的问卷') {
                    type = 1
                }
                this.$router.push({
                    path: '/issueQuestionnaire',
                    query: {id: obj.Id, type: type}
                })
            },
            hide() {
                this.visible = false
            },
            MeetingDel(Id) {
                let self = this
                this.$axios.get(
                    1027,
                    {
                        Id: Id,
                        Uid: self.userinfo.uid
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.loadData()
                            this.$message.success(res.data.msg)
                        } else {
                            this.$message.error('删除失败!')
                        }
                    }
                )
            },
            MybaseInfo() {
                let self = this
                this.$axios.post(
                    1033,
                    {
                        uid: self.userinfo.uid
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.MyDetails = res.data.message.Info
                            self.Mybase = res.data.message
                            self.MybaseId = res.data.message.Id
                        }
                    }
                )
            },

            Mynote() {
                let self = this
                this.$axios.post(
                    1041,
                    {
                        Type: 2,
                        plan_id: 123,
                        uid: self.userinfo.uid
                    },
                    res => {
                        console.log(res)
                        self.CourseStatus = true
                        self.NoteStatus = true
                        self.QuireStatus = false
                        if (res.data.code == 1) {
                            self.TitleText = '我的笔记'
                            self.NotaList = res.data.message.data
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            commentdel(id) {
                console.log(id)
                let self = this
                this.$axios.post(
                    1042,
                    {
                        Id: id,
                        uid: self.userinfo.uid
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            self.Mynote()
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            SaveNote(id) {
                let content = document.getElementById(id).value
                let self = this
                this.$axios.post(
                    1044,
                    {
                        Id: id,
                        uid: self.userinfo.uid,
                        Content: content
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.visible = false
                            self.Mynote()
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            submitMyinfo() {
                let self = this
                if (self.MyDetails == '') {
                    this.$message.error('请先输入个人简介信息')
                    return false
                }
                this.$axios.post(
                    1032,
                    {
                        userinfo: JSON.stringify(self.userinfo),
                        info: self.MyDetails,
                        Id: self.MybaseId
                    },
                    res => {
                        console.log(res.data.code)
                        //this.$message.info(res.data.message);
                        self.MybaseInfo()
                        self.MyinfoStatus = false
                        self.TitleStatus = false
                        // if(res.data.code==1){
                        //
                        // }else{
                        //     this.$message.error(res.data.message);
                        // }
                    }
                )
            },
            EditInfo() {
                this.MyinfoStatus = true
            },
            _hintShow() {
                this.hintMsg = !this.hintMsg
            },
            _cutShow() {
                this.leftShow = !this.leftShow
                this.hintMsg = false
                this.CourseStatus = !this.CourseStatus
                this.NoteStatus = false
                this.QuireStatus = false
                this.TitleText = '学习计划'
                this.loadData()
            },
            TitleShow() {
                this.TitleStatus = true
            },
            TitleHide() {
                this.TitleStatus = false
            },
            SaveLink(id, type) {
                console.log(this.active)
                if (this.active != -1) {
                    return false
                }
                if (type == 1) {
                    this.$router.push({path: '/SaveMeeting', query: {id: id}})
                } else {
                    this.$router.push({path: '/preview', query: {id: id}})
                }
            },
            powerLink(id, num) {
                console.log(id)
            },

            _hoverActive(i, type) {
                if (type == 1) {
                    this.active2 = i
                    this.active = -1
                } else {
                    this.active = i
                    this.active2 = -1
                }
            },
            createCourse() {
                this.$router.push({path: '/Addmeeting'})
            },
            emptyData() {
                this.Keyword = ''
                this.Speaker = ''
                this.Category ='';
                this.Page =1;
                this.loadData()
            },
            loadData(type) {
                let myUserId = ''
                if (type == 1) {
                    myUserId = this.userinfo.uid
                }
                let self = this
                this.$axios.get(
                    1023,
                    {
                        Limit: self.Limit,
                        Keyword: self.Keyword,
                        Speaker: self.Speaker,
                        UserId: self.userinfo.uid,
                        myUserId: myUserId,
                        Page: self.Page,
                        Category:self.Category,
                        StartTime:self.StartTime,
                        EndTime: self.EndTime ? (self.EndTime + ' 00:00') : '',

                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            self.QuireStatus = false
                            self.NoteStatus = false
                            self.CourseStatus = false
                            self.Mycount = res.data.data.MyCount
                            self.paperCount = res.data.data.PaperCount
                            self.quireCount = res.data.data.QuireCount
                            self.AllSpeaker = res.data.data.AllSpeaker
                            self.pageTotal = res.data.data.Count
                            self.infoData = res.data.data.data
                        } else {
                            self.infoData = ''
                            this.$message.error('没有更多数据')
                        }
                    }
                )
            },
            ChangePage(value) {
                this.Page = value
                this.loadData()
            },
            status(value) {
                console.log(value)
                this.UserStatus = value
            },
            MyQuire(value) {
                let self = this
                self.QuireType = value
                if (value == 'QuestionnaireId') {
                    this.TitleText = '我的问卷'
                } else {
                    this.TitleText = '考试测评'
                }

                this.$axios.get(
                    1038,
                    {
                        UserId: self.userinfo.uid,
                        Keyword: self.Keyword,
                        Field: value,
                        UserStatus: self.UserStatus
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.CourseList = res.data.message
                            console.log(self.CourseList)
                            self.CourseStatus = true
                            self.QuireStatus = true
                            self.NoteStatus = false
                        } else {
                            this.$message.error('没有更多数据')
                        }
                    }
                )
            },

            _onChange(v,dateString){
                this.StartTime = dateString[0];
                this.EndTime = dateString[1];

                console.log(this.StartTime);
                console.log(this.EndTime);

            },
            moment,

            //工单状态颜色
            _statusColor(i){
                if(i=='进行中'){
                    return '#2994FF';
                }else if(i=='已结束'){
                    return '#75C255';
                }

                //#d9d9d9
                else{
                    return '#ABB0BF';
                }
            },

            //紧急程度颜色
            _weightColor(i){
                if(i=='一般'){
                    return 'blue';
                }else if(i=='很急'){
                    return 'orange';
                }else if(i=='非常急'){
                    return 'red';
                }else{
                    return '';
                }
            },

            _showType (i) {
                this.showType = i
            },


        }
    }
</script>
<style lang="less" scoped>
    * {
        font-family: Microsoft YaHei;
    }

    .note-list {
        width: 100%;
        margin-right: 35px;
        display: inline-block;
        margin-bottom: 36px;
        padding: 10px;
        .meeting-title {
            /*width:329px;*/
            height: 113px;
            background: rgba(240, 240, 240, 1);
            border: 1px solid rgba(243, 243, 243, 1);
            margin-bottom: 15px;
            margin-top: 15px;
            padding: 0px 10px;
            .title-text {
                color: rgba(51, 51, 51, 1);
                font-size: 16px;
                width: 160px;
                margin-right: 25px;
            }
        }
        .notedetail {
            width: 100%;
            height: 74px;
            font-size: 16px;
            color: rgba(102, 102, 102, 1);
            word-break: break-all;
            overflow: hidden;
        }
    }

    .warp {
        display: flex;
        min-height: 800px;
    }

    .course-status {
        display: block;
        width: 45px;
        height: 21px;
        background: rgba(117, 113, 249, 1);
        margin-left: 20px;
        margin-bottom: 20px;
        color: #ffffff;
        padding: 3px;
        box-sizing: content-box;
    }

    .course-title {
        display: block;
        width: 95%;
        margin: auto;
        font-size: 18px;
        font-family: Microsoft YaHei;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .courser-time {
        display: block;
        font-size: 14px;
        width: 95%;
        margin: auto;
        margin-bottom: 5px;
    }

    .cut-btn {
        position: relative;
        width: 20px;
        height: 40px;
        line-height: 38px;
        background: #fff;
        border: 1px solid #e4e5e6;
        cursor: pointer;
        .hint-msg {
            position: absolute;
            top: 50%;
            right: -85px;
            transform: translateY(-50%);
            background: #000;
            opacity: 0.7;
            padding: 4px 8px;
            white-space: nowrap;
            font-size: 10px;
            line-height: 1.6;
            color: #fff;
            border-radius: 2px;
            .san {
                position: absolute;
                top: 50%;
                left: -5px;
                transform: translateY(-50%);
                border-right: 5px solid #000;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
            }
        }
    }

    .nav-list {
        background: #ffffff;
        width: 350px;
        /*margin-right: 16px;*/
        .logo {
            background: url('http://sk-ims-cabinet.sikegroup.com/Fo__7cNq3YfH3BWqcEl-r9oc_AqN') no-repeat;
            background-size: 100% 100%;
            display: flex;
            padding: 46px 10px;
        }
        .logo > img {
            width: 102px;
            height: 102px;
            border-radius: 50%;
            margin-right: 20px;
        }
        .header-title {
            width: 228px;
            color: #ffffff;
            font-size: 12px;
        }
        .header-name {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            height: 50px;
            line-height: 50px;
        }
        .nav-study {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            margin-left: 16px;
            margin-top: 14px;
            margin-bottom: 33px;
        }
        .navs-list {
            display: block;
            margin-left: 17px;
            margin-bottom: 57px;
        }
        .navs-list > li {
            height: 58px;
            display: inline-block;
            position: relative;
            padding-left: 62px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            // margin-right: 48px;
            width: 50%;
            margin-bottom: 27px;
        }
        .nav-weight {
            // height: 36px;
            line-height: 50px;
            font-family: Microsoft YaHei;
            font-size: 18px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
        }
        .nav-num {
            position: absolute;
            color: #ffffff;
            background: #ff4081ff;
            border-radius: 50%;
            display: block;
            width: 19px;
            height: 19px;
            text-align: center;
            line-height: 19px;
            left: 40px;
            top: 0px;
        }
        .my-base::before {
            content: '';
            width: 48px;
            height: 48px;
            position: absolute;
            left: 0;
            top: 0;
        }
        .my-course::before {
            background: url('http://sk-ims-cabinet.sikegroup.com/FmgAMkJUhdhlGIPwq6lpMUkC45ZN');
            background-size: 48px;
        }
        .my-Examination::before {
            background: url('http://sk-ims-cabinet.sikegroup.com/FkKQKL4LdI-i6SJmGVd7-XD4FazH');
            background-size: 48px;
        }
        .my-ques::before {
            background: url('http://sk-ims-cabinet.sikegroup.com/FkZMrbRt2npUZSw3aPSjpdp7-Ezz');
            background-size: 48px;
        }
        .my-note::before {
            background: url('http://sk-ims-cabinet.sikegroup.com/Ft3NZuIMVNaXhPVjf4breVmKjEC6');
            background-size: 48px;
        }
    }

    .list-warp {
        flex: 1;
        background: #ffffff;
        /*padding-bottom: 20px;*/
        .search-warp {
            background: #ffffff;
            /*height: 180px;*/
            /*margin-bottom: 10px;*/
            border-radius: 5px;
            .search-title {
                width: 100%;
                height: 47px;
                line-height: 47px;
                /*border-bottom: 1px solid #EDEDEDFF;*/
                font-size: 18px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                /*padding: 0px 24px;*/
            }
            .search-item {
                padding: 20px;
                padding-bottom: 0px;
            }
            .search-flex {
                display: inline-block;
                width: 400px;
                height: 36px;
                line-height: 36px;
                margin-bottom: 14px;
                .search-list-item {
                    margin-right: 28px;
                    display: flex;
                    .search-item-label {
                        font-size: 14px;
                        font-weight: bold;
                        color: rgba(102, 102, 102, 1);
                        margin-right: 10px;
                        width: 60px;
                        text-align: right;
                    }
                    .search-item-input {
                        flex: 1;
                    }
                    .search-item-button {
                        margin-right: 10px;
                        height: 35px;
                        line-height: 35px;
                        width: 76px;
                        text-align: center;
                    }
                }
            }
        }
        .lists-warp {
            background: #ffffff;
            padding: 22px;
            min-height: 800px;
        }
        .list {
            border: 1px solid #f3f3f3ff;
            display: inline-block;
            width: 95%;
            margin-bottom: 20px;
            border-radius: 5px;
        }
        .list-img {
            display: block;
            width: 100%;
            height: 170px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
            }
            .img-shadw {
                width: 100%;
                position: absolute;
                height: 45px;
                left: 0;
                bottom: 0;
                z-index: 10;
                line-height: 45px;
                background: rgba(0, 0, 0, 0.4);
                padding: 0px 14px;
                .img-text {
                    display: inline-block;
                    /*height: 45px;*/
                    /*line-height: 45px;*/
                    font-size: 17px;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }

        .list-detail {
            padding: 15px 18px;
            /*display: flex;*/
        }
        .list-title {
            flex: 1;
            width: 200px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .list-status {
            font-size: 17px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            opacity: 0.8;
            height: 30px;
            line-height: 30px;
            background: #f0f0f0;
            border-radius: 3px;
            width: 75px;
            text-align: center;
        }
        .list-status-red {
            background: #ff3e82;
            border-radius: 3px;
            color: #ffffff;
        }
        .list-addr {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .icon-bianji {
            // margin-left: 45px;
            margin-right: 10px;
            cursor: pointer;
        }
        .icon-shanchu- {
            cursor: pointer;
        }
    }


    .list-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px;
        padding-right: 20px;
        margin-bottom: 10px;
        border: 1px solid #E8E9EE;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        .order-base-msg{
            .order-title{
                color: #666;
                font-size: 18px;
                overflow: hidden;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                span{
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            .created-msg{
                display: flex;
                align-items: center;
                color: #999;
                font-size: 14px;
                margin-top: 10px;
                .created-person-msg{
                    img{
                        display: inline-block;
                        width: 25px;
                        height: 25px;
                        vertical-align: middle;
                        margin-right: 5px;
                        border-radius: 50%;
                    }
                    span{
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
                .created-date{
                    margin: 0 10px;
                }
            }
        }
    }

    .content-title {
        margin-bottom: 10px;
        margin-left: 8px;
        span {
            font-size: 18px;
            color: #333;
        }
        i {
            margin-left: 10px;
            font-size: 26px;
            cursor: pointer;
        }
    }


</style>
